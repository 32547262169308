import { del, get, post } from '@/api'
import { IDesignParams, IPageParams, IStickerParams, IStickerSearchParams, ISubmitParams } from '@/types'
import { uploadResoureceParams } from './types'

export const uploadResourece = (params: uploadResoureceParams) => {
  return post('/api/user_resources', params)
}

export const getUserResrouceApi = (): Promise<any> => {
  return get('/api/get_user_resource')
}

export const submitDesign = (params: ISubmitParams, id: number): Promise<any> => {
  return post('/api/customdesign/create', params)
}

export const updateDesign = (params: ISubmitParams, id: number): Promise<any> => {
  return post(`/api/customdesign/update`, params)
}

export const addCartToSY = (params: any): Promise<any> => {
  return post(`/api/shopify/add_to_cart`, params)
}

export const getStickerSearchApi = (params: IStickerSearchParams): Promise<any> => {
  return get('/api/stickers/search', { params })
}

export const getStickerCategoryApi = (params: IStickerParams): Promise<any> => {
  return get('/api/stickers-category/list', { params })
}

export const getBackgroundApi = (params: IPageParams): Promise<any> => {
  return get('/api/front-end-tool-palette/list', { params })
}

export const getDesignApi = (params: IDesignParams): Promise<any> => {
  return get('/api/customdesign/search', { params })
}

export const getDesignSingleApi = (id: number): Promise<any> => {
  return get(`/api/customdesign/get/${id}`)
}

export const getProductApi = (sku: string): Promise<any> => {
  return get(`/api/product/get-sku/${sku}`)
}

export const getCategoryListApi = (): Promise<any> => {
  return get(`/api/stickers/get-subject`)
}

export const getProductSkuApi = (id: string, appId: string, currency: string): Promise<any> => {
  return post(`/api/search_product`, {
    goodsId: id,
    appId,
    currency,
  })
}

export type addCartItemApiArgs = {
  pcaId: string;
  skuId: string;
  productId: number;
  quantity: number;
  customDesignId: number;
  effectImage: string[];
  itemName: string;
  skuName: string;
}
export const addCartItemApi = (params: addCartItemApiArgs): Promise<any> => {
  return post('/api/cart/cart/items', params)
}

export const getCartItemApi = (id: number): Promise<any> => {
  return get('/api/cart/cart/items', {
    params: {
      id,
    }
  })
}
export const delCartItemApi = (id: string): Promise<any> => {
  return del(`/api/cart/cart/items?id=${id}`, {
    
  })
}

export type addCartItemToWMApiParams = {
  goodsId: string;
  skuId: string;
  bizWid: string;
  quantity: number;
  cloudCustom: string;
  appId: string;
}
export const addCartItemToWMApi = (params: addCartItemToWMApiParams): Promise<any> => {
  return post(`/api/add_to_cart`, params)
}

export const batchQueryExternalSkuApi = (params: { ids: string }) => {
  return get('/api/product/batch-query-external-sku', { params })
}